<template>
  <div id="add-user-profile-modal">
    <AppModal dialogTitle="Add User" :disableClose="true">
      <b-form @submit.prevent="addUserProfile">
        <b-input-group prepend="Name">
          <b-form-input
            type="text"
            v-model="form.name"
            placeholder="Enter Name"
            @input="$v.form.name.$touch()"
            :state="!$v.form.name.$error"
          ></b-form-input>
          <b-form-invalid-feedback>
            <span v-if="!$v.form.name.required">
              Name is required.
            </span>
            <span v-if="!$v.form.name.safeName && $v.form.name.required">
              Name is not allowed.
            </span>
            <span v-if="!$v.form.name.uniqueName && $v.form.name.safeName &&
              $v.form.name.required">
              Name already exists.
            </span>
          </b-form-invalid-feedback>
        </b-input-group>

        <b-row>
          <b-col cols="12" md="4" lg="4">
            <div class="text-center">
              <div v-if="selectedUrl && selectedId">
                <i
                  class="fas fa-times-circle remove-icon"
                  @click="deselectAvatar">
                </i>
                <img
                  class="img-fluid avatar-preview"
                  :src="selectedUrl"
                  alt="Healthy Hip Hop Avatar Preview"
                />
              </div>
              
              <croppa
                v-model="form.file"
                accept=".jpeg,.png,.jpg"
                :width="180"
                :height="180"
                :placeholder="placeholder"
                placeholder-color="#068bcd"
                :placeholder-font-size="12"
                :prevent-white-space="true"
                canvas-color="#fff"
                :show-remove-button="true"
                remove-button-color="red"
                :remove-button-size="25"
                :show-loading="true"
                :loading-size="25"
                @file-type-mismatch="onFileTypeMismatch"
                @new-image="imageUpdate('new')"
                @image-remove="imageUpdate('removed')"
                v-else
              ></croppa>
            </div>
          </b-col>
          <b-col cols="12" md="8" lg="8">
            <div class="avatars text-center">
              <span
                class="position-relative ml-1 mr-1"
                v-for="avatar in avatars"
                :key="avatar.asset_id"
                @click="selectAvatar(avatar.asset_id, avatar.asset_url)">
                <img
                  class="img-fluid avatar"
                  :class="avatar.asset_id === selectedId ? 'selected' : ''"
                  :src="avatar.asset_url"
                  :alt="avatar.filename"
                />
              </span>
            </div>
          </b-col>
        </b-row>
        <div class="text-center mt-3">
          <b-button
            class="btn-h3-green"
            type="submit"
            :disabled="disableButton || !$v.form.name.required ||
              !$v.form.name.safeName || !$v.form.name.uniqueName">
            Submit
          </b-button>
          <b-button
            class="btn-h3-red"
            type="button"
            @click="$emit('close')">
            Cancel
          </b-button>
        </div>
      </b-form>
    </AppModal>

    <AppLoader :isLoading="loading" />
  </div>
</template>

<script>
  import 'vue-croppa/dist/vue-croppa.css';
  import { mapGetters } from 'vuex';
  import { required } from 'vuelidate/lib/validators';
  import ProfanityMixin from '../../mixins/ProfanityMixin';
  
  const mustBeImg = function (value) {
    const allowedTypes = [ 'image/jpeg', 'image/png', 'image/jpg' ];
    let isValid = false;

    if (value !== null)
      isValid = (!allowedTypes.includes(value.type)) ? false : true;
    else
      isValid = true;

    return isValid;
  };
  export default {
    mixins : [
      ProfanityMixin,
    ],
    data() {
      return {
        loading : false,
        form    : {
          file : {},
          name : null,
        },
        placeholder   : 'Choose an image',
        disableButton : true,
        safeName      : true,
        uniqueName    : true,
        progress      : 0,
        selectedUrl   : null,
        selectedId    : null,
      }
    },
    computed : {
      ...mapGetters({
        avatars : 'staticAsset/defaultAvatars',
      }),
    },
    components : {
      'AppLoader' : () => import('@/components/layout/AppLoader'),
      'AppModal'  : () => import('@/components/layout/AppModal'),
    },
    watch : {
      'form.name'(val) {
        this.safeName = true;
        this.uniqueName = true;
        if (val) {
          this.checkProfanity('safeName', val);
          this.checkUniqueName();
        }
      },
    },
    methods : {

      /**
       * Add User Profile
       */
      async addUserProfile() {
        this.loading = true;

        if (!this.selectedUrl || !this.selectedId)
          this.generateImgBlob();
        else {
          const formData = new FormData();
          formData.append('profileName', this.form.name);
          formData.append('profileImgUrl', this.selectedUrl);

          try {
            const res = await this.$http.post('api/user/profile', formData, {
              onUploadProgress : event =>
                this.progress = Math.round(event.loaded * 100 / event.total),
            });
            if (res) {
              this.$notify({
                group : 'notif',
                type  : 'success',
                title : 'Success',
                text  : 'Added User Profile successfully!',
              });
              this.$parent.$emit('update-users');
              this.loading = false;
              this.$emit('close');
            }
          } catch(err) {
            this.loading = false;
            this.$notify({
              group : 'notif',
              type  : 'error',
              title : 'Failed',
              text  : err.response.data.msg ? err.response.data.msg :
                'Oops! Something went wrong!',
            });
          }
        }
      },

      /**
       * Generate Image Blob before adding User Profile
       */
      generateImgBlob() {
        this.form.file.generateBlob(async (blob) => {
          const formData = new FormData();
          formData.append('profileName', this.form.name);
          formData.append('file', blob);

          try {
            const result = await this.$http.post('api/user/profile', formData, {
              onUploadProgress : e =>
                this.progress = Math.round(e.loaded * 100 / e.total),
            });
            if (result) {
              this.$notify({
                group : 'notif',
                type  : 'success',
                title : 'Success',
                text  : 'Added User Profile successfully!',
              });
              this.loading = false;
              this.$parent.$emit('update-users');
              this.$emit('close');
            }
          } catch(err) {
            this.loading = false;
            this.$notify({
              group : 'notif',
              type  : 'failed',
              title : 'Failed',
              text  : 'Oops! Something went wrong!',
            });
          }
        });
      },

      /**
       * Disable button on image croppa update
       * @param status
       */
      imageUpdate(status) {
        this.disableButton = (status === 'new') ? false : true;
      },

      /**
       * Check if File type is valid
       */
      onFileTypeMismatch () {
        this.placeholder = 'Please select a jpeg or png file.'
      },

      /**
       * Select Avatar
       */
      selectAvatar(id, url) {
        this.selectedUrl = url;
        this.selectedId = id;
        this.disableButton = false;
      },

      /**
       * Deselect Avatar
       */
      deselectAvatar() {
        this.selectedUrl = null;
        this.selectedId = null;
        this.disableButton = true;
      },

      /**
       * Check if Profile Name is Unique
       */
      checkUniqueName() {
        const profiles = this.$store.getters['subscription/userProfiles'];
        const mainUser = this.$store.getters['user/user'].nickname;
        const newUser = this.form.name;
        if (mainUser.toUpperCase() === newUser.toUpperCase())
          this.uniqueName = false;
        else if (profiles.length > 0) {
          profiles.forEach(item => {
            if (item.profile_name.toUpperCase() === newUser.toUpperCase())
              return this.uniqueName = false;
          });
        }
      },
    },
    validations : {
      form : {
        file : { required, mustBeImg },
        name : {
          required,
          safeName() {
            return this.safeName;
          },
          uniqueName() {
            return this.uniqueName;
          },
        },
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .avatar-preview {
    width: 185px;
    height: 185px;
    background-color: #fff;
    border: 2px dashed #068bcd;
  }

  .remove-icon {
    position: absolute;
    top: -2.5px;
    right: 1.5em;
    background-color: #fff;
    border: 1.5px solid #fff;
    border-radius: 1rem;
    color: red;
    cursor: pointer;
    filter: drop-shadow(-2px 2px 2px rgba(0,0,0,0.7));
    font-size: 1.3rem;
  }

  .avatar {
    margin: 0.25rem;
    height: 4.5em;
    width: 4.5em;
    background-color: #fff;
    border: 1px solid #e7932d;
    border-radius: 0.25rem;

    &:hover {
      cursor: pointer;
    }
  }

  .selected {
    border: 1px solid #068bcd;
  }

  .croppa-container {
    border: 2px dashed #068bcd;
  }

  .input-group-text {
    width: 10rem;
    background-color: #e7932d;
    color: #fff;
    font-family: "WickedMouse" !important;
    font-size: 0.75em;
    -webkit-text-stroke: 1px #a1409d !important;
  }

  .invalid-feedback {
    margin-left: 10rem;
  }
</style>